import React from 'react';
import { PlayButton as RobloxPlayButton } from 'Roblox';
import metadataConstants from '../constants/metadataConstants';
import eventStreamConstants, {
  EventStreamMetadata,
  SessionInfoType
} from '../../common/constants/eventStreamConstants';
import usePageReferralTracker from '../../common/hooks/usePageReferralTracker';
import { PageContext } from '../../common/types/pageContext';
import getExperienceAffiliateReferralUrlParams from '../../common/utils/getExperienceAffiliateReferralUrlParams';

const {
  usePlayabilityStatus,
  shouldShowUnplayableButton,
  DefaultPlayButton,
  Error
} = RobloxPlayButton;

type TPlayButtonProps = {
  attributionId: string;
};

export default function PlayButton({ attributionId }: TPlayButtonProps): JSX.Element {
  const { universeId = '', rootPlaceId = '', privateServerLinkCode = '', gameInstanceId = '' } =
    metadataConstants.metadataData() || {};
  const [playabilityStatus, refetchPlayabilityStatus] = usePlayabilityStatus(universeId);
  const { linkCode, linkType } = getExperienceAffiliateReferralUrlParams(
    window.location.toString()
  );
  const referralParams = usePageReferralTracker(
    eventStreamConstants.gameDetailReferral,
    [
      EventStreamMetadata.IsAd,
      EventStreamMetadata.Page,
      EventStreamMetadata.PlaceId,
      EventStreamMetadata.UniverseId,
      EventStreamMetadata.SortPos,
      EventStreamMetadata.Position,
      EventStreamMetadata.GameSetTypeId,
      EventStreamMetadata.GameSetTargetId,
      EventStreamMetadata.FriendId,
      EventStreamMetadata.NumberOfLoadedTiles,
      EventStreamMetadata.AppliedFilters,
      SessionInfoType.DiscoverPageSessionInfo,
      SessionInfoType.HomePageSessionInfo,
      SessionInfoType.GameSearchSessionInfo,
      SessionInfoType.SearchLandingPageSessionInfo
    ],
    ['privateServerLinkCode', 'placeId', 'launchData', 'gameInstanceId', 'referralUrl'],
    {
      [EventStreamMetadata.PlaceId]: rootPlaceId,
      [EventStreamMetadata.UniverseId]: universeId,
      [EventStreamMetadata.ShareLinkType]: linkType,
      [EventStreamMetadata.ShareLinkId]: linkCode
    }
  );

  return (
    <React.Fragment>
      <DefaultPlayButton
        placeId={rootPlaceId}
        rootPlaceId={rootPlaceId}
        universeId={universeId}
        privateServerLinkCode={privateServerLinkCode}
        gameInstanceId={gameInstanceId}
        refetchPlayabilityStatus={refetchPlayabilityStatus}
        playabilityStatus={playabilityStatus}
        hideButtonText={false}
        disableLoadingState={false}
        eventProperties={{
          [SessionInfoType.DiscoverPageSessionInfo]: referralParams.discoverPageSessionInfo,
          [SessionInfoType.GameSearchSessionInfo]: referralParams.gameSearchSessionInfo,
          [SessionInfoType.HomePageSessionInfo]: referralParams.homePageSessionInfo,
          [SessionInfoType.SearchLandingPageSessionInfo]:
            referralParams.searchLandingPageSessionInfo,
          [EventStreamMetadata.AttributionId]: attributionId,
          [EventStreamMetadata.Page]: referralParams.page,
          [EventStreamMetadata.SortPos]: referralParams.sortPos,
          [EventStreamMetadata.GameSetTypeId]: referralParams.gameSetTypeId,
          [EventStreamMetadata.UniverseId]: universeId,
          [EventStreamMetadata.AppliedFilters]: referralParams.appliedFilters,
          [EventStreamMetadata.PlayContext]: PageContext.GameDetailPage
        }}
      />
      {
        // for unplayable experiences, show the reason it's unplayable underneath the unplayable button
        shouldShowUnplayableButton(playabilityStatus) && (
          <Error playabilityStatus={playabilityStatus} errorClassName='error-message' />
        )
      }
    </React.Fragment>
  );
}
