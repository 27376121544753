import { ShareLinks } from 'Roblox';
import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import { fireEvent } from 'roblox-event-tracker';
import { gameDetailsPage } from '../common/constants/configConstants';
import InviteLinkInvalidModal from './components/InviteLinkInvalidModal';
import Carousel from './containers/Carousel';
import PlayButton from './containers/PlayButton';
import AboutTab from './containers/AboutTab';
import metadataConstants from './constants/metadataConstants';
import carouselConstants from './constants/carouselConstants';
import playButtonConstants from './constants/playButtonConstants';
import aboutTabConstants from './constants/aboutTabConstants';
import inviteLinkInvalidModalConstants from './constants/inviteLinkInvalidModalConstants';
import VotingPanelService from './containers/VotingPanelService';
import '../../../css/gameDetails/gameDetails.scss';
import sessionReferralUtils from '../common/utils/sessionReferralUtils';
import { AttributionType, getAttributionId } from '../common/utils/attributionUtils';

ready(() => {
  const { referralSessionInfo, referralPage } = sessionReferralUtils.extractReferralInfo();
  const attributionId = getAttributionId(AttributionType.GameDetailReferral);

  if (carouselConstants.gameDetailsCarouselContainer()) {
    const { universeId = '', placeId = '' } = metadataConstants.metadataData();
    render(
      <Carousel
        universeId={universeId}
        placeId={placeId}
        delay={carouselConstants.carouselConfigs.delay}
      />,
      carouselConstants.gameDetailsCarouselContainer()
    );
  }

  if (playButtonConstants.gameDetailsPlayButtonContainer()) {
    render(
      <PlayButton attributionId={attributionId} />,
      playButtonConstants.gameDetailsPlayButtonContainer()
    );
  }

  if (aboutTabConstants.gameDetailsAboutTabContainer()) {
    render(
      <AboutTab
        attributionId={attributionId}
        referralSessionInfo={referralSessionInfo}
        referralPage={referralPage}
      />,
      aboutTabConstants.gameDetailsAboutTabContainer()
    );
  }

  // Use VotingPanel.js from web-frontend instead of WWW
  // Intermediate state of the Voting Panel migration to web-frontend
  const votingPanelContainer = document.getElementById('voting-panel-container');
  if (votingPanelContainer) {
    const { placeId = '' } = metadataConstants.metadataData();

    try {
      VotingPanelService.checkPolicyAndLoad('#voting-panel-container', placeId);
    } catch {
      fireEvent(gameDetailsPage.votingPanelLoadFailure);
    }
  }

  const {
    experienceInviteLinkId = '',
    experienceInviteStatus = ''
  } = metadataConstants.metadataData();
  if (
    experienceInviteLinkId &&
    (experienceInviteStatus === ShareLinks.ExperienceInviteStatus.INVITER_NOT_IN_EXPERIENCE ||
      experienceInviteStatus === ShareLinks.ExperienceInviteStatus.EXPIRED) &&
    inviteLinkInvalidModalConstants.gameDetailsInviteLinkInvalidModalContainer()
  ) {
    const { universeId = '', placeId = '' } = metadataConstants.metadataData();
    render(
      <InviteLinkInvalidModal
        linkId={experienceInviteLinkId}
        linkStatus={experienceInviteStatus}
        placeId={placeId}
        universeId={universeId}
      />,
      inviteLinkInvalidModalConstants.gameDetailsInviteLinkInvalidModalContainer()
    );
  }
});
