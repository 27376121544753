import { useState, useEffect, useMemo } from 'react';
import bedev2Services from '../services/bedev2Services';

type TAppPolicyData = {
  shouldShowLikeFavoriteCounts: boolean | undefined;
};

type TUseGetAppPolicyData = TAppPolicyData & {
  isFetchingPolicy: boolean;
};

const useGetAppPolicyData = (): TUseGetAppPolicyData => {
  const [appPolicyData, setAppPolicyData] = useState<TAppPolicyData>({
    shouldShowLikeFavoriteCounts: undefined
  });

  const [isFetchingPolicy, setIsFetchingPolicy] = useState<boolean>(false);

  useEffect(() => {
    setIsFetchingPolicy(true);
    bedev2Services
      .getGuacAppPolicyBehaviorData()
      .then(data => {
        setAppPolicyData({
          shouldShowLikeFavoriteCounts: data.EnableAggregateLikesFavoritesCount
        });
      })
      .catch(() => {
        setAppPolicyData({
          shouldShowLikeFavoriteCounts: false
        });
      })
      .finally(() => {
        setIsFetchingPolicy(false);
      });
  }, []);

  return useMemo(() => {
    const { shouldShowLikeFavoriteCounts } = appPolicyData;

    return {
      shouldShowLikeFavoriteCounts,
      isFetchingPolicy
    };
  }, [appPolicyData.shouldShowLikeFavoriteCounts, isFetchingPolicy]);
};

export default useGetAppPolicyData;
