import React, { useEffect, useState } from 'react';
import { SocialLinksJumbotron, GameBadgesList } from 'Roblox';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { aboutTabTranslationConfig } from '../translation.config';
import metadataConstants from '../constants/metadataConstants';
import GameDescriptionSection from '../components/GameDescriptionSection';
import RecommendedGamesCarousel from '../../recommendedGames/RecommendedGamesCarousel';
import GameDetailsVirtualEventsSection from '../../gameDetailsVirtualEvents/GameDetailsVirtualEventsSection';
import PrivateServerList from '../../../../js/react/serverList/containers/PrivateServerList';
import { Survey } from '../components/SurveyComponent';
import { surveyLocation } from '../../common/constants/configConstants';
import useGameDetailsForUniverseId from '../hooks/useGameDetailsForUniverseId';
import experimentConstants from '../../common/constants/experimentConstants';
import bedev2Services from '../../common/services/bedev2Services';
import ExperienceRefund from '../components/ExperienceRefund';
import { TDiscoverySessionInfo } from '../../common/constants/eventStreamConstants';
import { PageContext } from '../../common/types/pageContext';

const { SocialLinkJumbotronType } = SocialLinksJumbotron;

const { layerNames, defaultValues } = experimentConstants;

type TAboutTabProps = {
  attributionId: string;
  referralSessionInfo: TDiscoverySessionInfo;
  referralPage: PageContext | undefined;
} & WithTranslationsProps;

export const AboutTab = ({
  attributionId,
  referralSessionInfo,
  referralPage,
  translate
}: TAboutTabProps): JSX.Element => {
  const { universeId = '', placeId = '' } = metadataConstants.metadataData() || {};

  const gameDetails = useGameDetailsForUniverseId(universeId);
  const [isEventsSectionUprankEnabled, setIsEventsSectionUpranked] = useState<boolean>(false);
  const [isEventsSectionRedesignEnabled, setIsEventsSectionRedesignEnabled] = useState<boolean>(
    false
  );

  useEffect(() => {
    bedev2Services
      .getExperimentationValues(layerNames.gameDetailsExposure, defaultValues.gameDetailsExposure)
      .then(data => {
        setIsEventsSectionUpranked(!!data.IsEventsSectionUprankEnabled);
        setIsEventsSectionRedesignEnabled(!!data.IsEventsSectionRedesignEnabled);
      })
      .catch(() => {
        setIsEventsSectionUpranked(defaultValues.gameDetailsExposure.IsEventsSectionUprankEnabled);
        setIsEventsSectionRedesignEnabled(
          defaultValues.gameDetailsExposure.IsEventsSectionRedesignEnabled
        );
      });
  }, []);

  return (
    <div className='game-about-tab-container'>
      <Survey
        locationName={surveyLocation.experienceDetails}
        resourceId={universeId}
        translate={translate}
      />
      {isEventsSectionUprankEnabled && (
        <GameDetailsVirtualEventsSection
          universeId={universeId}
          gameDetails={gameDetails}
          isEventsSectionRedesignEnabled={isEventsSectionRedesignEnabled}
          attributionId={attributionId}
          referralSessionInfo={referralSessionInfo}
          referralPage={referralPage}
        />
      )}
      <GameDescriptionSection
        universeId={universeId}
        placeId={placeId}
        gameDetails={gameDetails}
        translate={translate}
      />
      <SocialLinksJumbotron type={SocialLinkJumbotronType.Game} targetId={universeId} />
      <PrivateServerList showServers={false} translate={undefined} intl={undefined} />
      <GameBadgesList universeId={universeId} />
      {!isEventsSectionUprankEnabled && (
        <GameDetailsVirtualEventsSection
          universeId={universeId}
          gameDetails={gameDetails}
          isEventsSectionRedesignEnabled={isEventsSectionRedesignEnabled}
          attributionId={attributionId}
          referralSessionInfo={referralSessionInfo}
          referralPage={referralPage}
        />
      )}
      <div className='container-list games-detail'>
        <RecommendedGamesCarousel />
      </div>
      {gameDetails?.refundPolicy && (
        <ExperienceRefund gameDetails={gameDetails} translate={translate} />
      )}
    </div>
  );
};

export default withTranslations(AboutTab, aboutTabTranslationConfig);
